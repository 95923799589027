<template lang="pug">
  table.settings-table(:class="{ 'invalid-section': allInsurances.$error }")
    thead.settings-header
      tr
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.sections.insurance.name") }}
        th.table-item.header
          span {{ $t("plan_settings.basic_settings.fee") }}
    tbody
      InsuranceItem(
        v-for="(item, index) of mergedInsurances"
        :key="item.id || index"
        :insurance="item"
        :pairedInsurance="getPairedInsurance(allInsurances.$each.$iter, item.sources_insurance.id.$model, item.insurance_type.$model)"
      )
</template>

<script>
  import { EVERY_DAY, SELECTABLE } from "@/config/constants"
  import { getPairedInsurance } from "@/helpers/insurances"

  export default {
    props: {
      allInsurances: Object,
      mergedInsurances: Array
    },

    data() {
      return {
        everyDay: EVERY_DAY,
        selectable: SELECTABLE
      }
    },

    components: {
      InsuranceItem: () => import("./InsuranceItem")
    },

    methods: {
      getPairedInsurance
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
</style>
